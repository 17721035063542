const bill = {
  state: {
    beginUpdate: new Date(),
    pageType: 'monthBill',
    monthBillId: ''
  },
  mutations: {
    set_bill_update (state, data) {
      state.beginUpdate = data
    },
    set_bill_pageType (state, data) {
      state.pageType = data
    },
    set_bill_monthBillId (state, data) {
      state.monthBillId = data
    }
  }
}

export default bill
